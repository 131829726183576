// 配置路由相关信息
import VueRouter from "vue-router";
import Vue from "vue";

//注册
Vue.use(VueRouter);

const routes = [
  // {
  //   path: "/",
  //   component: () => import("@/view/home"),
  //   meta: {
  //     title: "首页",
  //     keepAlive: false,
  //   },
  // },
  {
    path: "/",
    component: () => import("@/view/specialOffer"),
    meta: {
      title: "首页",
      keepAlive: false,
    },
  },
  // {
  //   path: "/login",
  //   component: () => import("@/view/login"),
  //   meta: {
  //     title: "登录",
  //     keepAlive: false,
  //   },
  // },
  // {
  //   path: "/register",
  //   component: () => import("@/view/register"),
  //   meta: {
  //     title: "注册",
  //     keepAlive: false,
  //   },
  // },
  // {
  //   path: "/forget_password",
  //   component: () => import("@/view/forgetPassword"),
  //   meta: {
  //     title: "忘记密码",
  //     keepAlive: false,
  //   },
  // },
  // {
  //   path: "/search",
  //   name: "Search",
  //   component: () => import("@/view/search"),
  //   meta: {
  //     title: "搜索",
  //     keepAlive: true,
  //   },
  // },
  // {
  //   path: "/classification",
  //   name: "classification",
  //   component: () => import("@/view/classification"),
  //   meta: {
  //     title: "分类",
  //     keepAlive: false,
  //   },
  // },
  // {
  //   path: "/listgoods",
  //   name: "listGoods",
  //   component: () => import("@/view/listgoods"),
  //   meta: {
  //     title: "商品详情",
  //     keepAlive: false,
  //   },
  // },
  // {
  //   path: "/companys",
  //   name: "companys",
  //   component: () => import("@/view/companys"),
  //   meta: {
  //     title: "供应商详情",
  //     keepAlive: false,
  //   },
  // },
  // {
  //   path: "/categories",
  //   name: "categories",
  //   component: () => import("@/view/categories"),
  //   meta: {
  //     title: "全部分类",
  //     keepAlive: false,
  //   },
  // },
  // {
  //   path: "/suppliers",
  //   name: "suppliers",
  //   component: () => import("@/view/suppliers"),
  //   meta: {
  //     title: "供应商",
  //     keepAlive: false,
  //   },
  // },
  // {
  //   path: "/inquiry",
  //   name: 'Inquiry',
  //   component: () => import("@/view/inquiry"),
  //   meta: {
  //     title: "立即询价",
  //     keepAlive: false,
  //   },
  // },
  // {
  //   path: "/specialOffer",
  //   name: 'specialOffer',
  //   component: () => import("@/view/specialOffer"),
  //   meta: {
  //     title: "特价商品",
  //     keepAlive: false,
  //   },
  // },

  {
    path: "/cart",
    name: 'cart',
    // component: () => import("@/view/specialOffer/cart.vue"),
    component: () => import("@/view/specialOffer/cart.vue"),
    meta: {
      title: "购物车",
      keepAlive: false,
    },
  },
  {
    path: "/success",
    name: 'success',
    // component: () => import("@/view/specialOffer/cart.vue"),
    component: () => import("@/view/specialOffer/success.vue"),
    meta: {
      title: "提交成功",
      keepAlive: false,
    },
  },


  // {
  //   path: "/database",
  //   name: 'database',
  //   component: () => import("@/view/database"),
  //   meta: {
  //     title: "资料库",
  //     keepAlive: false,
  //   },
  // },

  {
    path: "/404",
    component: () => import("@/view/404.vue"),
    hidden: true,
    meta: {
      title: "404",
    },
  },

  { path: "*", redirect: "/404", hidden: true },
];

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const router = new VueRouter({
  mode: 'history',
  // mode: 'hash',
  base: process.env.BASE_URL,
  routes,
});

export default router;
