// 负责管理所有的自定义指令
// import Vue from 'vue'

// Vue.directive('指令名称', {
//     // 会在当前指令作用的dom元素 插入之后执行
//     // options 里面是指令的表达式
//     inserted: function (dom,options) {

//     }
// })

export const imagerror = {
  // 指令对象
  inserted(dom, options) {
    //会在当前dom元素插入到节点之后执行
    // options是 指令中的变量的解释  其中有一个属性叫做 value
    // dom 表示当前指令作用的dom对象
    // dom认为此时就是图片
    // 当图片有地址 但是地址没有加载成功的时候 会报错 会触发图片的一个事件 => onerror
    dom.onerror = function () {
      // 当图片出现异常的时候 会将指令配置的默认图片设置为该图片的内容
      // dom可以注册error事件
      dom.src = options.value; // 这里不能写死
    };
  },
};
